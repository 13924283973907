<script setup lang="ts">
import { get } from 'lodash'
import { type RouteLocationNamedRaw } from 'vue-router'

const headerStore = useHeaderStore()
const headerBackLink = computed(() => headerStore.backLink)

const { y } = useWindowScroll()

const open = ref(false)

const route = useRoute()
const router = useRouter()
const qrHuntRouter = useQRHuntRouter()

function onBack() {
    // const backTo = route.meta.backTo
    const backTo = toRaw(headerStore.backLink)

    if (!backTo) {
        return
    }

    if (backTo === ROUTE_TARGET.BACK) {
        router.go(-1)
        return
    }

    if (backTo === ROUTE_TARGET.PARENT) {
        const slugs: string[] = route.path.split('/')
        router.replace(slugs.slice(0, -1).join('/'))
        return
    }

    if (backTo === ROUTE_TARGET.PARENT_PARENT) {
        const slugs: string[] = route.path.split('/')
        router.replace(slugs.slice(0, -2).join('/'))
        return
    }

    if (backTo === ROUTE_TARGET.QRHUNT_BACK) {
        qrHuntRouter.back()
    }

    if (get(backTo, 'routeType') === 'qrhunt') {
        qrHuntRouter.push(backTo as QRHuntRoute)
        return
    }

    router.replace(backTo as RouteLocationNamedRaw | string)
}
</script>

<template>
    <div :class="['the-header', { 'the-header--background': y > 0 }]">
        <div class="the-header__content">
            <NuxtLink
                v-if="$route.name === PAGE.GAMES || !headerBackLink"
                style="display: inline-flex"
            >
                <Icon
                    name="hf-icon-32x"
                    class="the-header__logo"
                />
            </NuxtLink>

            <Icon
                v-else
                @click="onBack"
                class="the-header__back"
                name="back-circle-32x"
            />

            <NuxtLink
                :to="{ name: PAGE.PROFILE }"
                class="the-header__coins"
            >
                <CoinsDisplay />
            </NuxtLink>

            <Icon
                @click="open = true"
                name="menu-24x8"
                fluid
                class="the-header__menu-button"
            />
            <Modal v-model:open="open">
                <Menu @close="open = false" />
            </Modal>
        </div>
    </div>
</template>

<style lang="scss">
.the-header {
    // prettier-ignore
    @include v((
        icon-menu-size: 32px,
    ));

    display: flex;

    &__content {
        padding: 16px v(s-16) 16px;
        max-width: calc(768px);
        width: 100%;
        margin: auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    &__logo,
    &__back {
        width: v(icon-menu-size);
        height: v(icon-menu-size);
    }

    &__coins {
        display: inline-flex;
    }

    &__back {
        cursor: pointer;

        [stroke] {
            transition: stroke v(duration-default) v(easing-default);
            stroke: v(color-text);
        }

        [fill] {
            transition: fill v(duration-default) v(easing-default);
            fill: v(wild-sand);
        }

        [fill-opacity] {
            transition: fill-opacity v(duration-default) v(easing-default);
            fill-opacity: 0.6;
        }

        @include hover {
            [stroke] {
                stroke: v(rot);
            }
            [fill] {
                fill: v(wild-sand);
            }
            [fill-opacity] {
                fill-opacity: 1;
            }
        }
    }

    &__menu-button {
        cursor: pointer;
        width: v(icon-menu-size);
        height: v(icon-menu-size);

        svg {
            .menuSVG__bar1,
            .menuSVG__bar2 {
                width: 24px;
                transform: scaleX(0.7916666666666666);
                transform-origin: left;
                transition: transform v(duration-default) v(easing-default);
            }
            .menuSVG__bar1 {
                transform: scaleX(1);
            }
        }

        @include hover {
            svg {
                .menuSVG__bar1 {
                    transform: scaleX(0.7916666666666666);
                }
                .menuSVG__bar2 {
                    transform: scaleX(1);
                }
            }
        }
    }

    &--background {
        background: rgba(234, 234, 234, 0.9);
        backdrop-filter: blur(6px);
        transition: all v(transition-appear);
    }
}
</style>
