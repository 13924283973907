<script setup lang="ts">
const userStatsStore = useUserStatsStore()

// const currentValue = ref()
// const diffValue = ref()

// watch(
//     () => userStatsStore.coins,
//     (current, old) => {

//     currentValue.value = current

//     diffValue.value = current! - old!
// })
</script>

<template>
    <div class="coins-display">
        <Icon
            name="coin-18x"
            class="coins-display__icon"
        />

        <span class="coins-display__value">
            <!-- {{ diffValue }} [{{ currentValue }}] -->
            {{ userStatsStore.coins }}
        </span>
    </div>
</template>

<style lang="scss">
.coins-display {
    display: inline-flex;
    flex-flow: row nowrap;
    gap: v(s-10);
    place-items: center;

    &__icon {
    }

    &__value {
        font: v(font-copy);
        font-size: 14px;
        line-height: 1;
        margin-top: 2px;
    }
}
</style>
